$dark: rgb(58, 65, 74);
$dark-seethrough: rgba($dark, 0.88);

$less-bright: #e6e6e6;
$bright: #ffffff;

$paid: rgb(243, 102, 102);
$inProgress: rgb(247, 186, 107);
$done: rgb(165, 243, 102);
$refunded: rgb(247, 151, 252);
$deleted: rgb(135, 102, 243);

$icon-size-small: 30px;
$icon-size: 50px;
$icon-size-big: 100px;

$transition-time: 0.7s;
$base-radius: 0.5rem;


@import "../../node_modules/bootstrap/scss/bootstrap";


// @import "home";
@import "dashboard";
@import "bootstrap-tweaks";
@import "stampcards";
@import "fonts";

input.inline,
textarea.inline {
  background: inherit;
  border: 0;
  padding: 0;
  resize: none;
  outline: none;

  &:disabled {
    background: inherit;
  }
}

input[type="color"] {
  width: 4rem;
}

.text-align-center {

  p {
    text-align: center;
  }
}

.text-align-left {
  text-align: left !important;

  p {
    text-align: left;
  }
}

.text-small {
  font-size: smaller;
}

.text-x-small {
  font-size: x-small;
}

.animate {
  transition: all $transition-time;
}

.animate-open {
  max-height: 0;
  transition: max-height $transition-time;
  overflow: hidden;
}



////////////
// GENERAL
///////////

body,
html,
#root {
  height: 100%;
  min-height: 100vh;
  background: var(--bg-secondary);
}

#root {
  display: flex;
  flex-direction: column;
}

a,
a:hover,
a:visited {
  color: inherit;
  text-decoration: none;
}

.container {
  max-width: 900px;
}

.pointer {
  cursor: pointer;
}

.no-wrap {
  white-space: nowrap;
}

.center {
  display: flex;
  justify-content: center;
  align-items: center;
}

.superscript {
  font-size: 0.75rem;
  vertical-align: super;
}

.gap-2 {
  gap: 2rem;
}

.gap {
  gap: 1rem;
}

.gap-0 {
  gap: 0.75rem !important;
}

.nav-link {
  padding: 1rem;
  color: var(--primary);
}

.btn-light:hover {
  color: var(--primary);
  background: $dark;
  border-color: var(--primary);
  ;
}

.form-control:focus {
  border-color: var(--primary);
}

.flex-1 {
  flex: 1;
}

.flex-2 {
  flex: 2;
}

.bold {
  font-weight: bold;
}

.invisible {
  opacity: 0;
}

.streach {
  display: flex;
  flex-direction: column;
  flex: 1;
}

.mt-auto {
  margin-top: auto;
}

.resize-none {
  resize: none;
}

.bg-danger-light {
  background-color: #FEE4E2;
}

.bg-danger-lighter {
  background-color: #FEF3F2;
}

.max-h-50vh {
  max-height: 50vh;
}

.h-50vh {
  height: 50vh;
}

//////////
// SPINNER
//////////

.spinner {
  animation: rotate 2s linear infinite;
  z-index: 2;
  width: 100px;
  height: 100px;
  margin: 1rem;

  & .path {
    stroke: var(--primary);
    stroke-linecap: round;
    animation: dash 1.5s ease-in-out infinite;
  }

}

.spinner.small {
  width: 20px;
  height: 20px;
  margin: 0;
}

@keyframes rotate {
  100% {
    transform: rotate(360deg);
  }
}

@keyframes dash {
  0% {
    stroke-dasharray: 1, 150;
    stroke-dashoffset: 0;
  }

  50% {
    stroke-dasharray: 90, 150;
    stroke-dashoffset: -35;
  }

  100% {
    stroke-dasharray: 90, 150;
    stroke-dashoffset: -124;
  }
}

//////////
// HOME
//////////
.banner {
  min-height: 350px;
  background-size: cover;
  background-position: center;
  border-bottom: var(--primary) 2px solid;
  margin-bottom: 1rem;
}

.logo {
  max-width: 200px;
  max-height: 200px;
}

.logo-login {
  max-width: 350px;
  max-height: 350px;
}

.tile {
  width: calc(100% / 2 - 0.01px);
  padding: 1rem;

  .inner {
    padding: 0.5rem;
    border-radius: $base-radius;
    width: 100%;

    background: var(--bg-secondary);
    color: var(--primary);
    border: transparent 2px solid;
    transition: background-color $transition-time,
      color $transition-time;

  }
}

.checkout-upsell {
  display: flex;
  flex-wrap: nowrap;
  overflow-x: auto;

  .tile {
    width: calc(45% - 0.01px);
    flex: 0 0 auto;
  }
}


.tile-product {
  @extend .tile;

  .inner {
    display: flex;

    height: 10rem;
    padding: 0.75rem;
    border-color: var(--bs-primary);

    border-radius: $base-radius;
  }

}


$square-size: 150px;

.product-image {
  @extend .center;

  svg {
    width: $icon-size;
    height: $icon-size;
  }

  &.square {
    width: $square-size;
    height: $square-size;
  }

}

.preview {
  background: var(--bg-primary);
}

.open-at-edge {
  position: absolute;
  left: 0;
  right: 0;
  bottom: -15px;
  display: flex;
  justify-content: center;

  button {
    background: var(--bg-primary);
    color: var(--bs-primary);
    padding: 0 5px;
    border-radius: 50%;
    border: var(--bs-primary) 2px solid;
  }
}

/////////
// MENU
////////
.menu {
  background: var(--bg-primary);
  border-bottom: var(--primary) 2px solid;
  padding: 1rem;

  svg {
    color: var(--primary);
    width: $icon-size;
    height: $icon-size;
  }

  img {
    max-height: $icon-size;
  }
}

.nav-sublink {
  padding: 0 1rem;
  text-decoration: none;
  color: $bright;
}

.active {

  .nav-sublink,
  .nav-link {
    font-weight: bold;
  }
}

/////////
/// PRODUCT detail
/////////

.product-btn-container {
  @extend .center;
  height: 5rem;
  position: sticky;
  bottom: 0;
  left: 0;
  background: rgba(0, 0, 0, 0.3);
  width: 100%;

  button {
    width: 100%;
  }

}


//////////////
// CHECKOUT Button
//////////////
$footer_height: 5rem;

.footer-spacer.show {
  height: $footer_height;
}

.footer {
  position: fixed;
  bottom: 0;
  width: 100vw;
  height: 0;
  overflow: hidden;
  transition: height $transition-time;
  z-index: 100;
}

.footer.show {
  height: $footer_height;
}

.checkout-footer {
  border-top: var(--primary) 2px solid;
  background: var(--bg-primary);
}

.comapny-footer {
  color: var(--bs-primary);
  opacity: 0.8;
  background: var(--bg-primary);
}



.closed-neon {
  font-size: 3rem;
  color: #fee;
  text-shadow: 0 -40px 100px, 0 0 2px, 0 0 1em #ff4444, 0 0 0.5em #ff4444, 0 0 0.1em #ff4444, 0 10px 3px #000;
}

.blink {
  animation: blink linear infinite 2s;
}

.blink2 {
  animation: blink linear infinite 3s;
}

@keyframes blink {
  78% {
    color: inherit;
    text-shadow: inherit;
  }

  79% {
    color: #333;
  }

  80% {

    text-shadow: none;
  }

  81% {
    color: inherit;
    text-shadow: inherit;
  }

  82% {
    color: #333;
    text-shadow: none;
  }

  83% {
    color: inherit;
    text-shadow: inherit;
  }

  92% {
    color: #333;
    text-shadow: none;
  }

  92.5% {
    color: inherit;
    text-shadow: inherit;
  }
}

.paymentLogoSmall {
  height: 30px;
  margin-left: auto;
}

.paymentTypeContent {
  overflow: hidden;
  max-height: 0;
  -webkit-transition: max-height $transition-time;
  -moz-transition: max-height $transition-time;
  transition: max-height $transition-time;
  opacity: 0.5;

  &.active {
    opacity: 1;
    max-height: 300px;
  }
}

.discount-input {
  max-width: 20rem;
}

///////////
// STAFF HISTORY
///////////

.history-order {
  border-radius: $base-radius;
  padding: 0.5rem 1rem;
  margin: .3rem 1rem;
  cursor: pointer;
  background: $less-bright;
}

.history-order.active {
  box-shadow: 0 0 10px white;
}


.paid {
  border: 4px solid $paid
}

.inProgress {
  border: 4px solid $inProgress
}

.done {
  border: 4px solid $done
}

.refunded {
  border: 4px solid $refunded
}

.deleted {
  background: $deleted
}

///////////
// STAFF PRODUCTS
///////////

.staff-product-icon {
  padding: .8rem;
  margin: .5rem;
  background: $danger;
  border-radius: $base-radius;
  color: $less-bright;
  border: 1px $less-bright solid;

  svg {
    width: $icon-size-small;
    height: $icon-size-small;
  }
}

.delete {
  @extend .staff-product-icon;
}

.archive {
  @extend .staff-product-icon;
  background: $less-bright;
  color: $danger;
  border-color: $danger;
}

.delete-small {
  padding: .5rem;
  background: $danger;
  border-radius: $base-radius;
  color: $less-bright;
  border: 1px $less-bright solid;
}

.recycle {
  @extend .staff-product-icon;
  background: $success;
}

///////////
// Desktop
///////////

.qr-code {
  padding: 1rem;
  background: $bright;
  border-radius: 2.5rem;
}

///////////
// Save Toast
///////////
.save-toast {
  position: fixed;
  bottom: 0;
  right: 0;
  z-index: 0;
  overflow: hidden;

  &.show {
    z-index: 10001;

    .save-toast-container {
      right: 0;
    }
  }

}

.save-toast-container {
  background: var(--bs-light);
  border-radius: 0.2rem 0 0 0.2rem;
  padding: 0.3rem 1rem;
  transition: right 0.5s;
  position: relative;
  right: -500px;
  font-size: large;
}


///////////
// Opening Hours
///////////

#open-hours-tooltip {
  z-index: 20;

  .open-hours-tooltip {
    background-color: var(--bg-secondary);
    color: var(--primary);
    border: 2px solid var(--primary);
    position: relative;
    top: 10px;
    border-radius: $base-radius;
    padding: 1rem;
  }
}


///////////
// SIZING
///////////
@media only screen and (max-width: 500px) {
  .banner {
    min-height: 150px;
  }

  .tile {
    min-height: 150px;
  }

  .tile-product {
    width: 100%;
    min-height: 50px;
  }

  .checkout-upsell {
    .tile-product {
      width: 80%;
    }
  }

  .btn-swish-other-device {
    display: none;
  }
}