.rdw-editor-wrapper {
    margin-top: 1rem;
    border: 1px solid white;
}


.rdw-editor-toolbar {
    border: none;
    background: inherit;
}

.rdw-editor-wrapper.readOnly {
    border: 0;
    margin-top: 1rem;
}

.rdw-editor-wrapper.readOnly .rdw-editor-main {
    padding: 0;
}

.rdw-editor-main {
    padding: 1rem;
    color: var(--primary)
}