///////////
// STAFF DASHBOARD
///////////

.order-column {
    flex: 2;
    background: $less-bright;
    margin: 0.2rem 1rem;
    border-radius: $base-radius;

    &.closed {
        background-color: rgb(252, 206, 214)
    }

    &.done {
        flex: 1;
    }
}

.hover-drag {
    box-shadow: 0px 0px 40px var(--primary);
}

.dashboard-order {
    border-radius: $base-radius;
    margin: 0.3rem;
    padding: 0.3rem;
    cursor: pointer;

    svg {
        width: $icon-size-small;
        height: $icon-size-small;
    }
}

.order-pill {
    padding: 0.2rem 0.5rem;
    border-radius: $base-radius;
    margin: 0.2rem;
    cursor: pointer;
    text-shadow: 1px 1px 2px white;
}