@font-face {
    font-family: 'papercuts';
    src: url('./papercuts-2.ttf') format('truetype');
}

body {
    font-family: var(--font-primary);

    @if not var_exists('--font-primary') {
        font-family: var(--bs-body-font-family);
    }
}

h4 {
    font-family: var(--font-highlight);
}