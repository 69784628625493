.stamp-card {
    position: relative;
    z-index: 10;
    transform-style: preserve-3d;

    &:hover {

        transform: translateY(-3px) scale(1.05) rotateX(15deg);
    }

    transition: 0.3s;
}

.stamp-card-content:hover::after {
    transform: rotate(25deg);
    top: -40%;
    opacity: 0.15;
}

.stamp-card-content::after {
    content: '';
    z-index: 10;
    width: 200%;
    height: 100%;
    top: -90%;
    left: -20px;
    opacity: 0.1;
    transform: rotate(45deg);
    background: linear-gradient(to top, transparent, #fff 15%, rgba(255, 255, 255, 0.5));
    transition: 0.3s;

    position: absolute;
}

.stamp-card-content {
    border-radius: $base-radius;
    background: var(--bg-secondary);
    min-height: 200px;
    width: 350px;
    max-width: 90vw;
    box-shadow: 0 5px 10px rgba(255, 255, 255, 0.665);
    overflow: hidden;
    position: relative;
}

.stamp {
    margin: 0.3rem;
    width: 22%;
    aspect-ratio: 1 / 1;
    border: var(--primary) 1px dashed;

    &.last {
        background: linear-gradient(45deg, #f8f4d03e, var(--primary));
        box-shadow: 0px 0px 20px var(--primary);
        filter: blur(1px)
    }
}

.stamp-logo {
    max-width: 100%;
    max-height: 100%;
}


.rgb:hover {
    &::after {
        z-index: -5;
        content: "";
        background: linear-gradient(45deg,
                #ff0000 0%,
                #ff9a00 10%,
                #d0de21 20%,
                #4fdc4a 30%,
                #3fdad8 40%,
                #2fc9e2 50%,
                #1c7fee 60%,
                #5f15f2 70%,
                #ba0cf8 80%,
                #fb07d9 90%,
                #ff0000 100%) repeat 0% 0% / 300% 100%;
        position: absolute;
        inset: -3px;
        border-radius: $base-radius;
        filter: blur(8px);
        animation: rgb 6s linear infinite;
    }
}

@keyframes rgb {
    0% {
        background-position: 0% 50%;
    }

    50% {
        background-position: 100% 50%;
    }

    100% {
        background-position: 0% 50%;
    }
}